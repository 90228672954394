//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';
  
class ConfirmationAppointment extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      success : false,
      error : false
    }
  }
  
  componentDidMount() {
    let db = this.props.db;
    
    this.props.client.auth.loginWithCredential(new AnonymousCredential()).then( user => {
      let mCollection = db.collection('confirmationAppointments');
      try {
        let savedDate = new Date(this.props.date);
        console.log("Conformation inserted Date :" + savedDate.toUTCString());
        mCollection.insertOne({ appointmentDate   : savedDate,
                                appointmentMonth  : savedDate.getMonth(),
                                firstName         : this.props.info.firstName,
                                lastName          : this.props.info.lastName,
                                telephone         : this.props.info.telephone,
                                client            : this.props.info.client
        }).then((insertedItem) => {
          
          this.setState({
            success     : true,
            error       : false,
            insertedId  : insertedItem.insertedId.toHexString()
          });
        });
      } 
      catch(error) {
        console.log(error);
        this.setState({
          success : false,
          error : true
        });
      }
    });
  }

  // database doesn't have daylight saving and doesn't account for it.
  // 480 is California timezone offset w/o DLS
  daylightSavingFix(inputTime) {
    let outputTime = new Date(inputTime);
    let diff = inputTime.getTimezoneOffset() - 480;
    
    if(diff) {
      outputTime = new Date( inputTime.valueOf() + diff * 60 * 1000 );
    }
    
    return outputTime;
  }
  
  render() {
    if(this.state.success) {
      return(
        <div>
          <br/>
          <h3> 
            Your Appointment has been scheduled for:
          </h3>
          <br/>
          <kbd>
            <div className="confirmationInfo">
              <h3 className="clearText">{this.daylightSavingFix(this.props.date).toLocaleString('en-US')}</h3>
              <h5 className="clearText">{this.props.info.firstName}</h5>
              <h5 className="clearText">{this.props.info.lastName}</h5>
              <h5 className="clearText">{this.props.info.telephone}</h5>
              <h5 className="clearText">Current client? {this.props.info.client.toString()}</h5>
            </div>
          </kbd>
          <br/>
          <h3> 
            If you need to reschedule, cancel or look up your
            appointment time please call our office at 805-922-3880
            and one of us will help you.
          </h3>
          <br/>
{//       <h3> Your appointment id is {this.state.insertedId} </h3> 
}
        </div>
      )
    }      
    else { 
      if(this.state.error) {
        return(
          <h3> The reservation was not successful, please try again or call in your appointment.</h3>
        );
      } else {
        return(
          <div className="spinner-grow m-5" role="status">
            <span className="sr-only">Waiting for server...</span>
          </div>
        )
      }
    }
  }

}

export default ConfirmationAppointment;