//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import BreadCrumb from './BreadCrumb.js';

class ContactMe extends Component {

  render() {
    const changeWebState = this.props.changeWebState;
    
    return(
      <div>
        <BreadCrumb crumbs = {[{  title : "Menu",
                                  action : () => changeWebState('menu')}]}/>
        <h3 className="clearText"> Ike Iqbal, CPA</h3>
        <h4 className="clearText"><a href="https://www.google.com/maps/place/1407+N+Broadway,+Santa+Maria,+CA+93454/@34.9664569,-120.4380643"> 1407 N. Broadway, Santa Maria, CA 93454 </a></h4>
        <h4 className="clearText"> Tel: 805-922-3880 Fax: 805-922-0534</h4>
        <a className="clearText" href="mailto:IkeCPA@pronet.net">IkeCPA@pronet.net</a>
      </div>
    )
  }
}
export default ContactMe;