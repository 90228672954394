//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import BreadCrumb from './BreadCrumb.js';
import appleDownload from '../img/AppBadge.png';
import googleDownload from '../img/getit_google.png';
import amazonDownload from '../img/available_amazon.png';

class CheckRefund extends Component {
  
  DownloadAppButtons() {
    return (
      <div className="row">
        <div className="col-md-4">
          <a href="https://apps.apple.com/app/apple-store/id414113282">
            <img src={appleDownload} alt="apple irs app download" />
          </a>
        </div>
        <div className="col-md-4">
          <a href="https://play.google.com/store/apps/details?id=gov.irs">
            <img src={googleDownload} alt="google irs app download" />
          </a>
        </div>
        <div className="col-md-4">
          <a href="https://www.amazon.com/Internal-Revenue-Service-IRS2Go/dp/B00TTCW8S2/">
            <img src={amazonDownload} alt="amazon irs app download" />
          </a>
        </div>
      </div>
    );
  }
  
  render() {
    const changeWebState = this.props.changeWebState;
    const DownloadAppButtons = this.DownloadAppButtons;
    
    return(
      <div>
        <BreadCrumb crumbs = {[{  title : "Menu",
                                  action : () => changeWebState('menu')}]}/>
        <div className="clearText">
          <h4>Tax Return Refunds</h4>
          <p className="">Refunds take several weeks to be processed and sent to you. Direct
           deposit is typically several days faster than receiving a paper check.</p>
          <h4>Checking your refund status</h4>
          <p>You can check your status by clicking the link below or by downloading
           any version of the IRS's phone and tablet app.</p>
          <a className="text-warning noBlur" href="https://www.irs.gov/refunds">
            <strong>https://www.irs.gov/refunds</strong>
          </a>
          <h4 className="topPad"> IRS2go App </h4>
          <DownloadAppButtons />
        </div>
      </div>
    );
  }
}
export default CheckRefund;