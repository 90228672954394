//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import Menu from './Menu.js';
import Tools from './Tools.js';
import ContactMe from './ContactMe.js';
import ScheduleAppointment from './ScheduleAppointment.js';
import CheckRefund from './CheckRefund.js';
import GetAvailableAppointments from '../serverTools/GetAvailable.js';
import { 
  Stitch, 
  RemoteMongoClient } from 'mongodb-stitch-browser-sdk';

class WebState extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      webState       : 'menu',
      serverReturned : false
    };
    
  }
  
  changeWebState = (newstate) => {
    console.assert(
      newstate === 'menu'                 ||
      newstate === 'contactMe'            ||
      newstate === 'scheduleAppointment'  ||
      newstate === 'tools'                ||
      newstate === 'checkRefund',
      "Cannot change website to an invalid state.\n"
    );
    
    this.setState({webState : newstate});
  }
  
  componentDidMount() {
    this.client = Stitch.initializeDefaultAppClient('thinlayer-qiblt');
    this.db = this.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('wingZero');
    GetAvailableAppointments(this.db, this.client, this.setAppointmentBlock);
  }
  
  setAppointmentBlock = (availableTimes) => {
    this.setState({
      availableTimes : availableTimes,
      serverReturned : true
    });
  }
  
  render() {
    const changeWebState = this.changeWebState;
    
    
    switch (this.state.webState) {
      case 'menu':
        return (
            <Menu changeWebState = {changeWebState}/>
        );
        break;
      case 'contactMe':
        return (
          <ContactMe changeWebState = {changeWebState}/>
        );
        break;
      case 'scheduleAppointment':
        return (
          <ScheduleAppointment  changeWebState  = {changeWebState}
                                client          = {this.client}
                                db              = {this.db}
                                availableTimes  = {this.state.availableTimes}
                                serverReturned  = {this.state.serverReturned}/>
        );
        break;
      case 'checkRefund':
        return (
          <CheckRefund changeWebState = {changeWebState}/>
        );
        break;
      case 'tools':
        return (
          <Tools changeWebState = {changeWebState}/>
        );
        break;
      default :
        return null;
    }
  }
}

export default WebState;