import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import WebState from './components/WebState.js';

import Background from './img/officeOutsideNorm.jpg';
import MobileBackground from './img/officeSignMobile.jpg';

class App extends React.Component {

  render() {
    return (
      <div className="App" id="app">
        <div className="container-fluid">
          <div className="card bg-dark text-white">
            <img src={MobileBackground} className="card-img bkTransparency d-block d-md-none" alt="offic Sign"/> 
            <img src={Background} className="card-img bkTransparency d-none d-md-block " alt="office building"/>
            <div className="card-img-overlay">
              
              <WebState />
            </div>
          </div>
        </div>
      </div>
    );      
  }  
}

export default App;
