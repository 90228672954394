//Copywrite Jason Iqbal 2019

import React, {Component} from 'react';
import BreadCrumb from './BreadCrumb';
import SelectAppointment from './SelectAppointment.js';
import ChooseAppointment from './ChooseAppointment.js';
import GiveInfoAppointment from './GiveInfoAppointment.js';
import ConfirmationAppointment from './ConfirmationAppointment.js';

class ScheduleAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAppointment : new Date(),
      selectedDay         : [],
      appointmentState    : 'selectDay'
    };
    
  }
  
  setDate = (date) => {
    this.setState({
      selectedAppointment : date,
      appointmentState    : 'giveInfo'
    });
  }
  
  setSelectedDay = (day) => {
    this.setState({
      selectedDay : day,
      appointmentState : 'selectTime'
    })
  }
  
  setContactInfo = (info) => {
    this.info = info;
  }
  
  setAppointmentState = (flow) => {
    this.setState({
      appointmentState : flow
    });
  }
  
  MenuButton( selectingTime ) {
    const changeWebState = this.props.changeWebState;
    const changeSchedulerState = this.setAppointmentState;
    let shinyStonesTrail = [
      {
        title  : "Menu",
        action : () => changeWebState('menu')
      }    
    ];
    
    if (selectingTime) {
      shinyStonesTrail.push(
        {
          title   : "Calendar",
          action  : () => changeSchedulerState('selectDay')
        }
      );
    }

      
    return (
      <div>
        <BreadCrumb crumbs =  {shinyStonesTrail}/>
        <h3 className="clearText"> Schedule an Appointment </h3>
      </div>
    );
  }
  
  render() {

    const setDate   = this.setDate;
    const setAppointmentState = this.setAppointmentState;
    const setContactInfo = this.setContactInfo;
    
    switch(this.state.appointmentState) {
      case 'selectDay' :
        return(
          <div>
            {this.MenuButton(false)}
            
            <SelectAppointment  Choose  = {this.setSelectedDay}
                                client = {this.props.client}
                                db = {this.props.db}
                                availableTimes = {this.props.availableTimes}
                                serverReturned = {this.props.serverReturned}/>
                                
          </div>
        );
        break;
      case 'selectTime' :
        return(
          <div>
            {this.MenuButton(true)}
            
            <ChooseAppointment  day                 = {this.state.selectedDay}
                                setDate             = {setDate}/>
          </div>
        );
        break;
      case 'giveInfo' :
        return(
          <div>
            {this.MenuButton(false)}
            
            <GiveInfoAppointment  date = {this.state.selectedAppointment}
                                  setAppointmentState = {setAppointmentState}
                                  setContactInfo = {setContactInfo}
                                  client = {this.props.client}
                                  db = {this.props.db}/>
          
          </div>
        );
        break;
      case 'confirmation' :
        return(
          <div>
            {this.MenuButton(false)}

            <ConfirmationAppointment  date = {this.state.selectedAppointment}
                                      setAppointmentState = {setAppointmentState}
                                      info = {this.info}
                                      client = {this.props.client}
                                      db = {this.props.db}/>
          </div>
        );
        break;
      default :
        return null;
    }
  }
}
export default ScheduleAppointment;