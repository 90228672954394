//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';

class MenuItem extends Component {

  render() {
    const title = this.props.title;
    const action = this.props.action;
    
    return(
      <div className="row bottomPad">
        <div className="col">
          <button type="button" className="btn btn-warning"
           onClick={action}>{title}</button>
        </div>
      </div>
    );
  }
}

export default MenuItem;