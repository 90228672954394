//Copywrite Jason Iqbal 2019
import React from 'react'

class SingleTime extends React.Component {
  
  // database doesn't have daylight saving and doesn't account for it.
  // 480 is California timezone offset w/o DLS
  daylightSavingFix(inputTime) {
    let outputTime = new Date(inputTime);
    let diff = inputTime.getTimezoneOffset() - 480;
    
    if(diff) {
      outputTime = new Date( inputTime.valueOf() + diff * 60 * 1000 );
    }
    
    return outputTime;
  }  
  
  render() {
    const setDate = this.props.setDate;
    const sTime = this.props.sTime;
    const currentTime = new Date();
    let displayTime;
    
    if(this.props.sTime){
      displayTime = this.daylightSavingFix(sTime);
      if(displayTime.valueOf() > currentTime.valueOf()) {
        return(
          <tr>
            <th scope="col">
              

              <button type="button" className="btn btn-primary selectBtn" onClick={()=> {
                setDate(sTime);
              }}><h5>{displayTime.toLocaleTimeString('en-us')}</h5></button>
              
            </th>
          </tr>
        );
      }
    }
    return(
      <tr><th className="d-none" scope="col"></th></tr>
    );
  }
} 
export default SingleTime;