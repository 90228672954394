//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';

class PayrollOutput extends Component {
  
  render() {
    let grossIncome = Number(this.props.grossIncome.toFixed(2));
    let sSTax = Number(this.props.sSTax.toFixed(2));
    let medicareTax = Number(this.props.medicareTax.toFixed(2));
    let eDDTax = Number(this.props.eDDTax.toFixed(2));
    let currencyStr = new Intl.NumberFormat('en-US', {style : 'currency', currency: 'USD'});
    
    return(
      <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Money Earned (Gross)</th>
              <td> {currencyStr.format(grossIncome)} </td>  
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col"></th>
              <td></td>
            </tr>
            <tr>
              <th scope="col" className="negativeNum">Disability Taxes (CA SDI)</th>
              <td>{currencyStr.format(eDDTax)} </td>
            </tr>
            <tr>
              <th scope="col" className="negativeNum">Social Security Taxes (Federal)</th>
              <td>{currencyStr.format(sSTax)} </td>
            </tr>
            <tr>
              <th scope="col" className="negativeNum">Medicare Taxes (Federal)</th>
              <td>{currencyStr.format(medicareTax)} </td>
            </tr>
            <tr><th scope="col"></th><td></td></tr>
            <tr>
              <th scope="col" className="negativeNum">Total deductions</th>
              <td>{currencyStr.format(eDDTax + medicareTax + sSTax)} </td>
            </tr>
            <tr>
              <th scope="col">Your Check (Net)</th>
              <td>{currencyStr.format(grossIncome - (eDDTax + medicareTax + sSTax))} </td>
            </tr>
          </tbody>
        </table>
    );
  }
}

export default PayrollOutput;