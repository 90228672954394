//Copywrite Jason Iqbal 2019
import React from 'react';

class BreadCrumb extends React.Component {
  myItem = (title, action) => {
    
    return(
      <li className="breadcrumb-item active" key={title}>
        <button type="button" className="btn btn-warning btn-sm"
         onClick={action}>{title}</button>
      </li>
    );
  }
  
  render() {
    
    return(
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb bottomPad">
          {this.props.crumbs.map((breadCrumb) => this.myItem(
            breadCrumb.title,
            breadCrumb.action
          ))}
        </ol>
      </nav>
    );
  }
}
export default BreadCrumb;