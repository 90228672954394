//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import DaySquare from './DaySquare.js';

class Week extends Component {
  
  render() {
    return (
      <tr>
        <DaySquare day = {this.props.week[0]} Choose = {this.props.Choose}/>
        <DaySquare day = {this.props.week[1]} Choose = {this.props.Choose}/>
        <DaySquare day = {this.props.week[2]} Choose = {this.props.Choose}/>
        <DaySquare day = {this.props.week[3]} Choose = {this.props.Choose}/>
        <DaySquare day = {this.props.week[4]} Choose = {this.props.Choose}/>
        <DaySquare day = {this.props.week[5]} Choose = {this.props.Choose}/>
        <DaySquare day = {this.props.week[6]} Choose = {this.props.Choose}/>
      </tr>
    );
  }
}
export default Week;