//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import MenuItem from './MenuItem.js';
import BreadCrumb from './BreadCrumb.js';
import PayrollOutput from './PayrollOutput.js';

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours : 0,
      rate : 0
    };
  }
  
  calculate = () => {
    this.setState({hours : document.getElementById("hoursWorked").value,
                   rate  : document.getElementById("dollarRate").value});
    
  }
  
  componentDidMount() {
    let input = document.getElementById("employeePaycheckCalculator");
    let calc = this.calculate;
    
    input.addEventListener("keyup", function(event) {
      if(event.keyCode ===13) {
        event.preventDefault();
        calc();
      }
    });
    
  }
  
  render() {
    let grossIncome = this.state.hours * this.state.rate;
    let eDDTax = grossIncome * 0.012;
    let sSTax = grossIncome * 0.062;
    let medicareTax = grossIncome * 0.0145;
    const changeWebState = this.props.changeWebState;
    
    return(
      <div id="employeePaycheckCalculator">
        <BreadCrumb crumbs = {[{  title : "Menu",
                                  action : () => changeWebState('menu')}]}/>
        <h2 className="title"> Employee Paycheck Calculator </h2>
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizeing-default totHours">
                  Hours Worked
                </span>
              </div>
              <input type="text" className="form-control" id="hoursWorked"/>
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizeing-default wage">
                  Dollars Per Hour
                </span>
              </div>
              <input type="text" className="form-control" id="dollarRate"/>
            </div>
          </div>
          <div className="col-md-3" />
        </div>
        <MenuItem title = "Calculate"
                  action = {this.calculate}/>
                  
        <PayrollOutput  grossIncome = {grossIncome}
                        sSTax = {sSTax}
                        medicareTax = {medicareTax}
                        eDDTax = {eDDTax}/>
        <p className="font-italic text-warning">*This check is if you make less than $128,298 and live in California, USA in 2021.</p>
      </div>
    );
  }
}

export default Tools;