//Copywrite Jason Iqbal 2019

import {AnonymousCredential} from 'mongodb-stitch-browser-sdk';

export default function getAvail(db, client, setAppointmentBlock) {
  
  // makes the data structure that holds appointments and packs in the appointments.
  function splitArray(availTimes){
    let today = new Date();
    let completedBlock = [];

    for(let m = 0; m < 3; m++) {
      completedBlock[m] = [];
      for(let w = 0; w < 6; w++) {
        completedBlock[m][w] = [];
        for(let d = 0; d < 7; d++) {
          completedBlock[m][w][d] = [];
        }
      }
    }

    for( let monthIndex = 0, availIndex = 0, weekIndex = 0, numApps = 0;
     monthIndex < 3;
     monthIndex++, weekIndex = 0) {

      today.setDate(1);
      do {
        while(
         availIndex < availTimes.length &&
         today.getDate() === availTimes[availIndex].getDate() &&
         today.getMonth() === availTimes[availIndex].getMonth()
        ) {
          completedBlock[monthIndex][weekIndex][availTimes[availIndex].getDay()][numApps++] = availTimes[availIndex];
          
          availIndex++;
        }
        numApps = 0;
        
        if(today.getDay() === 6) {
          weekIndex++;
        }
        
        today.setTime(today.getTime() + 1000 * 60 * 60 * 24);
      } while(today.getDate() !== 1);
    }
    
    return completedBlock;
  }
  
  
  
  // Safely gets the next month
  function IncrementMonth(workingDate) {
    const mNumber = workingDate.getMonth();
    
    if( mNumber < 11) {
      workingDate.setMonth(mNumber + 1);
    }
    else {
      workingDate.setFullYear(workingDate.getFullYear() + 1);
      workingDate.setMonth(0);
    }
    return workingDate.getMonth();
  }
  
  
  
  // Fetchs the data and sorts it.
  function serverGet() {

    let today = new Date();
    today.setDate(15); //removes edge case where incr month has less days and skips.
    
    client.auth.loginWithCredential(new AnonymousCredential()).then( user => {
      let mCollection = db.collection('appointments');
      mCollection.find({ $or: [ { appointmentMonth : today.getMonth() }, 
                                { appointmentMonth : IncrementMonth(today)}, 
                                { appointmentMonth : IncrementMonth(today)} ] }).asArray().then(
        (appointments) => {
          let curTime = new Date();
          IncrementMonth(today);
          
          let availTimes = [];
          for(let i = 0; i < appointments.length; i++) {
            let singleDate = new Date(appointments[i].appointmentDate.toLocaleString("en-US",{timeZone : "America/Los_Angeles" }));
            
            if(singleDate.valueOf() > curTime.valueOf() && singleDate.valueOf() < today.valueOf()) {
              availTimes.push(singleDate); 
            } 
          };
          availTimes.sort((a, b) => a.valueOf() - b.valueOf());
          
          setAppointmentBlock(splitArray(availTimes));
        }
      ).catch( error => {
        console.log(error);
      });
    });
  }
  
  serverGet();
  return null;
}