//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import MenuItem from './MenuItem.js';

class Menu extends Component{  
    
  hoursOfOperation = () => {
    let weekdayClosingTime = "8:00pm";
    let workSeason = true;
    let today = new Date();
    let seasonEnd = new Date();
    let seasonStart = new Date();
    
    seasonEnd.setMonth(3)
    seasonEnd.setDate(18); //April 18th
    
    seasonStart.setMonth(1)
    seasonStart.setDate(1); //February 1st
    
    if (today.valueOf() < seasonStart.valueOf() || today.valueOf() > seasonEnd.valueOf()) {
      weekdayClosingTime = "4:00pm";
      workSeason = false;
    }
      
    function saturdayOff(busy) {
      if (busy) {
        return(
          <tr>
            <td>Saturday </td><td>10:00am - 3:00pm</td>
          </tr>
        )
      }
      return(
        <tr>
            <td>Saturday </td><td>Closed</td>
        </tr>
      )
    }
    
    function angieWorking(busy) {
      if (busy) {
        return(
          <p className="clearText text-warning">
            <br/>
            <u><i>Angelina speaks Spanish and is available</i></u><br/>
            Mon, Tue, Wed and Fri 4:30pm  - 7:00pm<br/>
            Saturday              10:00am - 2:00pm<br/>
          </p>
        )
      }
    }
    
    return(
      <div>
        <table className="clearText mx-auto">
          <tbody>
            <tr>
              <td>Sunday </td><td>Closed</td>
            </tr>
            <tr>
              <td>Monday </td><td>9:00am  - {weekdayClosingTime}</td>
            </tr>
            <tr>
              <td>Tuesday </td><td>9:00am  - {weekdayClosingTime}</td>
            </tr>
            <tr>
              <td>Wednesday </td><td>9:00am  - {weekdayClosingTime}</td>
            </tr>
            <tr>
              <td>Thursday </td><td>9:00am  - {weekdayClosingTime}</td>
            </tr>
            <tr>
              <td>Friday </td><td>9:00am  - {weekdayClosingTime}</td>
            </tr>
              {saturdayOff(workSeason)}
          </tbody>
        </table>
        {angieWorking(workSeason)}
      </div>
    );
  }
    
  render(){
    const changeWebState = this.props.changeWebState;
    
    return(
      <div>
        <h1 className="siteName"> Accounting & Tax Service </h1>
        <div className="topPad">
          <MenuItem title = "Contact Me" 
                    action = {() => changeWebState('contactMe')}/>
          <MenuItem title = "Schedule an Appointment" 
                    action = {() => changeWebState('scheduleAppointment')}/>
          <MenuItem title = "Check on tax refund" 
                    action = {() => changeWebState('checkRefund')}/>
          <MenuItem title = "Tools"
                    action = {() => changeWebState('tools')}/>
        </div>
        {this.hoursOfOperation()}
      </div>
    );
  }
	
}

export default Menu;