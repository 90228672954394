//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import SingleTime from './SingleTime.js';

class ChooseAppointment extends Component {
  
  getFirstEntry() {
    let eSpot = 0;
    while(!(this.props.day[eSpot])) eSpot++;
    //eSpot never out of bounds because a day is only clickable if it has times in it. 
    return this.props.day[eSpot];
  }
  
  render(){
    
    return (
      <table className="table table-dark">
        <thead>
          <tr>
            <th scope="col"><h4>{this.getFirstEntry().toDateString()}</h4></th>
          </tr>
        </thead>
        <tbody>
          <SingleTime sTime = {this.props.day[0]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[1]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[2]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[3]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[4]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[5]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[6]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[7]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[8]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[9]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[10]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[11]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[12]} setDate = {this.props.setDate}/>
          <SingleTime sTime = {this.props.day[13]} setDate = {this.props.setDate}/>
        </tbody>
      </table>
    );
  }
}
export default ChooseAppointment;