//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import MonthSquare from './MonthSquare.js';

class SelectAppointment extends Component {
  
  render() {
    const Choose  = this.props.Choose;
    if(this.props.serverReturned) {
      return(
        <div>
          <MonthSquare availTimes     = {this.props.availableTimes}
                       serverReturned = {this.props.serverReturned}
                       Choose         = {Choose}/>
        </div>
      );
    }
    return(
      <div>
        <div class="spinner-border text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}

export default SelectAppointment;