//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import Week from './Week.js';
import BackButton from '../img/back.png';
import ForwardButton from '../img/forward.png';

class MonthSquare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthSelector : 0
    };
    this.month = new Date();
    this.month.setDate(15);
    this.IncrementMonth = this.IncrementMonth.bind(this);
    this.DecrementMonth = this.DecrementMonth.bind(this);
  }
  
  titleIncr() {
    const mNumber = this.month.getMonth();
    
    if( mNumber < 11) {
      this.month.setMonth(mNumber + 1);
    }
    else {
      this.month.setMonth(0);
    }    
  }
  
  titleDecr() {
    const mNumber = this.month.getMonth();   
    
    if( mNumber > 0) {
      this.month.setMonth(mNumber - 1);
    }
    else {
      this.month.setMonth(11);
    }
  }
  
  IncrementMonth() {
    
    if(this.state.monthSelector < 2) {
      
      this.setState({monthSelector : this.state.monthSelector + 1});      
      this.titleIncr();
    }
    else {
      this.titleDecr();
      this.titleDecr();
      this.setState({monthSelector : 0});
    }
    
    return this;
  }
  
  DecrementMonth() {
    
    if(this.state.monthSelector > 0) {
      
      this.setState({monthSelector : this.state.monthSelector - 1});
      this.titleDecr();
    }
    else {
      this.titleIncr();
      this.titleIncr();
      this.setState({monthSelector : 2});
    }
    
    return this;
  }
  
  weekdayTitle(weekdayName) {
    return(
    <th scope="col"> 
      <h6 className="d-none d-sm-block">{weekdayName}</h6>
      <h6 className="d-sm-none">{weekdayName.charAt(0)}</h6>
    </th>
    );
  }
  
  render(){
    const IncrementMonth = this.IncrementMonth;
    const DecrementMonth = this.DecrementMonth;
    let monthSelector = this.state.monthSelector;
    
    if(this.props.serverReturned) {
      return (
        <table className="table table-bordered">
          <thead className="thead-dark compactTable">
            <tr>
              <th />
              <th />
              <th>
                <button className="btn btn-light LRControls clearText" type="button"
                 aria-label="previous" onClick={DecrementMonth}>
                  <img src={BackButton} alt="previous"/>
                </button>
              </th>
              <th scope="col">
                <h3 className="d-none d-sm-block">
                  {new Intl.DateTimeFormat('en-US', {month:"long"})
                   .format(this.month)}
                </h3>
                <h4 className="d-sm-none">
                  {new Intl.DateTimeFormat('en-US', {month:"long"})
                   .format(this.month).slice(0,3)}
                </h4>
              </th>
              <th>
                <button className="btn btn-light LRControls clearText" type="button"
                aria-label="next" onClick={IncrementMonth}>
                  <img src={ForwardButton} alt="next"/>
                </button>
              </th>
              <th />
              <th />
            </tr>
            <tr>
              {this.weekdayTitle("Sunday")}
              {this.weekdayTitle("Monday")}
              {this.weekdayTitle("Tuesday")}
              {this.weekdayTitle("Wednesday")}
              {this.weekdayTitle("Thursday")}
              {this.weekdayTitle("Friday")}
              {this.weekdayTitle("Saturday")}
            </tr>
          </thead>
          <tbody>
            <Week week = {this.props.availTimes[monthSelector][0]} Choose = {this.props.Choose}/>
            <Week week = {this.props.availTimes[monthSelector][1]} Choose = {this.props.Choose}/>
            <Week week = {this.props.availTimes[monthSelector][2]} Choose = {this.props.Choose}/>
            <Week week = {this.props.availTimes[monthSelector][3]} Choose = {this.props.Choose}/>
            <Week week = {this.props.availTimes[monthSelector][4]} Choose = {this.props.Choose}/>
            <Week week = {this.props.availTimes[monthSelector][5]} Choose = {this.props.Choose}/>
          </tbody>
        </table>
      );
    }
    else {
      return (
        <table className="table table-dark">
          <thead className="thead-dark">
            <tr>
              <th scope="col"> Sunday </th>
              <th scope="col"> Monday </th>
              <th scope="col"> Tuesday </th>
              <th scope="col"> Wednesday </th>
              <th scope="col"> Thursday </th>
              <th scope="col"> Friday </th>
              <th scope="col"> Saturday </th>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      );
    }
  }    
 
}
export default MonthSquare;