//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';
import padImage from '../img/giveInfoPadSM.png';
import MenuItem from './MenuItem.js';
import { AnonymousCredential } from 'mongodb-stitch-browser-sdk';


class GiveInfoAppointment extends Component {

  componentDidMount() {
    let db = this.props.db;
   
    this.props.client.auth.loginWithCredential(new AnonymousCredential()).then( user => {
      let mCollection = db.collection('holdAppointments');
      mCollection.find({appointmentDate : this.props.date}).asArray().then(
        (appointments) => {
          if(appointments.length === 0) {
            let savedDate = new Date(this.props.date);
            console.log("GiveInfoAppointment inserted Date:" + savedDate.toUTCString());
            mCollection.insertOne({ appointmentDate   : savedDate,
                                    appointmentMonth  : savedDate.getMonth()
            }).catch( 
            function(e) {
              alert("database failed to insert and hold your appointment");
              this.props.setAppointmentState("select");
            });
            
          }
          else {
            alert("This appointment is not available anymore.");
            window.setTimeout(() => {
            this.props.setAppointmentState("select");
            }, 1500);
          }
          console.log(appointments);
        }
      ).catch( error => {
        console.log(error);
      });
    });
    this.timesUp = setTimeout(()=> this.props.setAppointmentState("selectDay"), 70000);
  }
  
  componentWillUnmount() {
    clearTimeout(this.timesUp);
  }
  
  TextEntry(props) {
    return( 
      <div className="input-group input-group-sm mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id={props.answerID + "Title"}>
            {props.userPrompt}
          </span>
        </div>
        <input type="text" className="form-control" aria-label={props.userPrompt}
         aria-describedby={props.answerID + "Title"} id={props.answerID} />
      </div>
    );
  }
  
  CheckboxEntry(props) {
    
    return(
      <div className="form-group form-check row">
        <input type="checkbox" className="form-check-input" id={props.answerID}
         aria-label={props.answerID}
         aria-describedby={props.answerID + "Title"} 
        />
        <label className="form-check-label" id={props.answerID + "Title"}
         htmlFor={props.answerID}>
          {props.userPrompt}
        </label>
      </div>
    );
  }
  
  IsInfoValid(info) {
    if(info.firstName && info.lastName && info.telephone) {
      return true;
    } 
    return false;
  }

  ScheduleIt() {
    let contactInfo = {
      firstName : document.getElementById("firstName").value,
      lastName  : document.getElementById("lastName").value,
      telephone : document.getElementById("phoneNumber").value,
      client    : document.getElementById("checkClient").checked
    };
    
    if(this.IsInfoValid(contactInfo)) {
      this.props.setContactInfo(contactInfo);
      this.props.setAppointmentState("confirmation");
    }
    else {
      alert("Invalid contact information.");
    }
    return null;
  }

  render() {
    let Question = this.TextEntry;
    let Check = this.CheckboxEntry;
    
    return(
      <div className="giveInfoInput">
        <img className="imgNotePad" src={padImage} alt="pad of paper" />
        <div className="centeredOverImg">
        
          <Question answerID    = "firstName"
                    userPrompt  = "First Name"/>
          <Question answerID    = "lastName"
                    userPrompt  = "Last Name"/>
          <Question answerID    = "phoneNumber"
                    userPrompt  = "Phone Number"/>
          <Check    answerID   = "checkClient"
                    userPrompt = "Have you been a client in last 3 years?"/>
                    
          <MenuItem title = "Schedule Me In"
                    action = {() => this.ScheduleIt()}/>
        
        </div>
      </div>
    );
  }
}
export default GiveInfoAppointment;