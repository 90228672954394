//Copywrite Jason Iqbal 2019
import React, {Component} from 'react';

class DaySquare extends Component {
  constructor(props) {
    super(props);
//    this.state = {
//      availabilityColor : "table-success"
//    };
    
//    if(this.props.day.length < 5) {
//      this.availabilityColor = "bg-warning";
//    }
//    else {
      this.availabilityColor = "table-sucess";
//    }
  }
  
  componentDidMount(){
//    if(this.props.day.length < 5) {
//      this.setState({availabilityColor : "bg-warning"});
//    }
//    else {
//      this.setState({availabilityColor : "table-sucess"});
//    }
  }
  
  render() {
    const Choose = this.props.Choose;
    
    if(this.props.day.length){
      return (
        <td className={this.availabilityColor + " cell"}>
          <button type="button" className="btn btn-primary cellButton" onClick={()=> Choose(this.props.day)}>
            <h5 className="d-none d-md-block">
              {this.props.day[0].toDateString()}
            </h5>
            <h5 className="d-md-none">
            {this.props.day[0].getDate()}
            </h5>
          </button>
        </td>
      );
    }
    return (
      <td className="cell">

      </td>
    );
  }
}
export default DaySquare;